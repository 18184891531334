import React from 'react';
import styled from 'styled-components';
import spotify from '../../icons/spotify.svg';

import {
  FAQS_LINK,
  CONTACT_US_LINK,
  MERCHANT_INTEREST_LINK,
  POSTS_LINK,
  ABOUT_LINK,
  PLAYLIST_LINK,
  TERMS_LINK,
  PRIVACY_LINK,
  ECARDS_LINK,
} from '../../constants/links';

const Footer = () => {
  const year: number = new Date().getFullYear();

  return (
    <Layout>
      <Links>
        <LinkContainer>
          <span>
            <a href={FAQS_LINK}>FAQ</a>
            <a href={ABOUT_LINK}>About</a>
            <a href={CONTACT_US_LINK}>Contact Us</a>
            <a href={PLAYLIST_LINK}>
              <img src={spotify} />
              Our Playlist
            </a>
          </span>
          <span>
            <a href={ECARDS_LINK}>eCards</a>
            <a href={POSTS_LINK}>Blog</a>
            <a href={MERCHANT_INTEREST_LINK}>Merchants</a>
          </span>
        </LinkContainer>
      </Links>
      <CopyrightAndNavLinks>
        <Copyright>
          © {year}. All rights reserved. Dedicated to those who shine. <span>|</span>
        </Copyright>
        <NavLinks>
          <NavLink href={TERMS_LINK}>Terms</NavLink>
          <NavLink href={PRIVACY_LINK}>Privacy</NavLink>
        </NavLinks>
      </CopyrightAndNavLinks>
    </Layout>
  );
};

export default Footer;

const Layout = styled.section`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 64px 32px 23px 32px;
  width: 100%;

  @media ${(props) => props.theme.breakpoints.tabletP} {
    padding: 32px;
    width: 50%;
  }
`;

const Links = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  height: 128px;
  margin: 16px 0 0 0;
  z-index: 1;

  @media ${(props) => props.theme.breakpoints.laptopL} {
    bottom: 0;
    width: auto;
    margin: 16px 0 24px 0;
  }
`;

const LinkContainer = styled.div`
  display: flex;
  flex-direction: row;

  span {
    display: flex;
    flex-direction: column;
    margin-right: 49px;
  }

  a {
    font-size: 16px;
    line-height: 20px;
    white-space: nowrap;
    font-weight: 700;
    margin-bottom: 8px;
  }

  img {
    margin: 0 4px -2px 0;
    width: 16px;
    height: 16px;
  }

  @media ${(props) => props.theme.breakpoints.tablet} {
    display: flex;
    margin: 4px 0;
    img {
      margin: 0 4px -2px 0;
      width: 16px;
      height: 16px;
    }

    a {
      font-size: 18px;
      white-space: nowrap;
      line-height: 24px;
      font-weight: 700;
      margin-right: 32px;
    }
  }
`;

const Copyright = styled.p`
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  margin: 4px 0 4px 0;

  span {
    display: none;
  }

  @media ${(props) => props.theme.breakpoints.laptopL} {
    font-size: 14px;
    line-height: 18px;
  }
`;

const CopyrightAndNavLinks = styled.div`
  color: ${(props) => props.theme.colors.darkGray};
  display: flex;
  flex-direction: column;
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  z-index: 1;

  @media ${(props) => props.theme.breakpoints.tabletP} {
    padding: 8px 0 0 0;
  }

  @media ${(props) => props.theme.breakpoints.laptopL} {
    bottom: 0;
    font-size: 14px;
    line-height: 18px;
    margin: 16px 0 24px 0;
  }
`;

const NavLinks = styled.div`
  display: flex;
  margin-left: -8px;
  margin-top: 10px;
`;

const NavLink = styled.a`
  line-height: 24px;
  margin: 4px 0;
  padding: 0 8px;

  @media ${(props) => props.theme.breakpoints.laptopL} {
    font-size: 14px;
    line-height: 18px;
  }
`;
