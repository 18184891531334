import React, { FC } from 'react';
import { motion } from 'framer-motion';
import styled, { ThemeProvider } from 'styled-components';

import MetaTags from '../../components/MetaTags';

import { theme } from '../../styles/theme';

import Background from '../../components/Background';
import Header from '../../components/Header2';
import { StackOfPictures } from '../../components/StackOfPortraits';
import { getMainAppFullPath } from '../../constants/getUrl';
import {
  CREATE_CARD_LINK,
  SEND_FREE_GROUPCARD_LINK,
  SLACK_INSTALL_LINK,
} from '../../constants/links';
import { PORTRAIT_URLS } from '../../data/portraits';
import { stepByStepUnordered } from '../../data/stepByStepData';
import useFormFactors from '../../helpers/useFormFactors';
import initiatorEditCard from '../../images/initiator_card.png';
import slack_bulk from '../../images/slack_bulk.png';
import man_with_glasses from '../../images/man_with_glasses.png';
import maxGift from '../../images/max_group_gift.png';
import slack_ss from '../../images/slack_ss_combined.png';
import { ImageAndCopySection } from '../../sections/ImageAndCopy';
import Join from '../../sections/Join';
import { SlackTitledBanner } from '../../sections/TitledBanner';
import FooterNew from '../../sections/common/FooterWithoutFormFactorHookSplitted';
import Companies from '../../sections/marketing/Companies';
import CuratedGifts from '../../sections/marketing/CuratedGifts';
import HowItWorks from '../../sections/marketing/HowItWorks';
import LoveIllume from '../../sections/marketing/LoveIllume';
import { SlackMeaningfullGroupGifts } from '../../sections/marketing/MeaningfullGroupGifts';
import { all_type_of_carousel_data } from '../../sections/marketing/data/products_and_notes_carousel_data';
import { testimonialsV2 } from '../../sections/marketing/data/testimonialsv2';
import SlackPricingSection from '../../sections/pricing/SlackPricingSection';
import { SLACK_PRICING_DATA } from '../../sections/pricing/data/B2B_PRICING_DATA';
import { HowItWorksStepByStep } from '../splitanygifts';
import FaqSection from '../../sections/Faq';
import { SLACK_FAQS } from './pricing';
import { slackColumns } from '../../sections/pricing/components/tabledata.example';
import { slack_feature_data } from '../../data/feature.data';

const Layout = styled(motion.div)`
  display: flex;
  flex-direction: column;
  overflow: hidden;
  padding: 0 24px;
  position: relative;
  width: 100%;
  .pricing-card {
    min-height: 550px;
  }

  @media ${(props) => props.theme.breakpoints.laptop} {
    -webkit-backface-visibility: hidden;
  }
`;

const MaxGroupGiftContainer = styled.div`
  position: relative;
`;

const ImageAndShadowContainer = styled.div<{ shadowColor: string }>`
  img {
    display: block;
    width: 100%;
    border-radius: 8px;
  }
  .shadow {
    width: 100%;
    height: 100%;
    background: ${(props) => props.shadowColor || 'rgba(0, 0, 0, 0.2)'};
    position: absolute;
    top: 12px;
    left: 8px;
    z-index: -1;
    border-radius: 8px;
  }
`;

export const ImageAndShadow = ({
  src,
  alt,
  shadowColor,
}: {
  src: string;
  alt: string;
  shadowColor: string;
}) => {
  return (
    <ImageAndShadowContainer shadowColor={shadowColor}>
      <img src={src} alt={alt} />
      {/* <div className='shadow'></div> */}
    </ImageAndShadowContainer>
  );
};

const StyledImageAndCopySection = styled(ImageAndCopySection)`
  margin-bottom: 90px;
  @media (min-width: 768px) {
    margin-bottom: 200px;
  }
`;

const StyledPricingSection = styled(SlackPricingSection)`
  margin-top: 48px;
  padding: 0 24px;
`;

const flippedTexts = ['Celebrate', 'Thank', 'Support'];

const StyledCompanies = styled(Companies)`
  margin-top: 53px;
  padding-top: 0px;
  margin-bottom: 50px;
  @media (min-width: 768px) {
    margin-top: 100px;
    margin-bottom: 100px;
  }
`;

const StyledHeader = styled(Header)`
  @media (min-width: 768px) {
    padding: 0 calc(24px + 24px);
  }
`;

const StyledCuratedGifts = styled(CuratedGifts)`
  margin-bottom: 85px;
  @media (min-width: 768px) {
    margin-bottom: 0px;
  }
`;

const AddToSlackButton = styled.a`
  align-items: center;
  color: #000;
  background-color: #fff;
  border: 1px solid #ddd;
  border-radius: 4px;
  display: inline-flex;
  font-family: Lato, sans-serif;
  font-size: 16px;
  font-weight: 600;
  height: 48px;
  justify-content: center;
  text-decoration: none;
  width: 236px;
`;

export const SlackIcon = styled.svg`
  height: 20px;
  width: 20px;
`;

const Heading = styled.p`
  z-index: 2;
  color: ${(props) => props.theme.colors.blackText};
  font-size: 40px;
  font-weight: 600;
  line-height: 48px;
  margin: 46px 0 0 0;
  text-align: center;

  @media ${(props) => props.theme.breakpoints.tabletP} {
    font-size: 44px;
    line-height: 55px;
  }

  @media ${(props) => props.theme.breakpoints.laptop} {
    font-size: 64px;
    line-height: 70px;
  }
`;

export const AddToSlackBtn = () => (
  <AddToSlackButton href='https://slack.com/oauth/v2/authorize?scope=&amp;user_scope=&amp;redirect_uri=www.dev.tryillume.com%2Fslack%2Fonboarding&amp;client_id=286363241653.5130980011361'>
    <SlackIcon xmlns='http://www.w3.org/2000/svg' viewBox='0 0 122.8 122.8'>
      <path
        d='M25.8 77.6c0 7.1-5.8 12.9-12.9 12.9S0 84.7 0 77.6s5.8-12.9 12.9-12.9h12.9v12.9zm6.5 0c0-7.1 5.8-12.9 12.9-12.9s12.9 5.8 12.9 12.9v32.3c0 7.1-5.8 12.9-12.9 12.9s-12.9-5.8-12.9-12.9V77.6z'
        fill='#e01e5a'
      ></path>
      <path
        d='M45.2 25.8c-7.1 0-12.9-5.8-12.9-12.9S38.1 0 45.2 0s12.9 5.8 12.9 12.9v12.9H45.2zm0 6.5c7.1 0 12.9 5.8 12.9 12.9s-5.8 12.9-12.9 12.9H12.9C5.8 58.1 0 52.3 0 45.2s5.8-12.9 12.9-12.9h32.3z'
        fill='#36c5f0'
      ></path>
      <path
        d='M97 45.2c0-7.1 5.8-12.9 12.9-12.9s12.9 5.8 12.9 12.9-5.8 12.9-12.9 12.9H97V45.2zm-6.5 0c0 7.1-5.8 12.9-12.9 12.9s-12.9-5.8-12.9-12.9V12.9C64.7 5.8 70.5 0 77.6 0s12.9 5.8 12.9 12.9v32.3z'
        fill='#2eb67d'
      ></path>
      <path
        d='M77.6 97c7.1 0 12.9 5.8 12.9 12.9s-5.8 12.9-12.9 12.9-12.9-5.8-12.9-12.9V97h12.9zm0-6.5c-7.1 0-12.9-5.8-12.9-12.9s5.8-12.9 12.9-12.9h32.3c7.1 0 12.9 5.8 12.9 12.9s-5.8 12.9-12.9 12.9H77.6z'
        fill='#ecb22e'
      ></path>
    </SlackIcon>
    Add to Slack
  </AddToSlackButton>
);

const Homepage: FC = () => {
  const { mobile } = useFormFactors();
  return (
    <ThemeProvider theme={theme}>
      <MetaTags
        title='The Celebration Platform | illume'
        description={
          'Create fun, stand-out ecards, group cards, and group gifts with illume. For birthdays, anniversaries, or any occasion. Join the new way of celebrating.'
        }
      />
      <Layout>
        <Background showTopBlob={false} showBottomBlob={true} showMiddleBlob={false} />

        <StyledHeader showCreateGiftBtn={false} />

        <SlackMeaningfullGroupGifts
          flippedTextDirection='right'
          headline='Easier.'
          flippingTexts={flippedTexts}
          showCta={!mobile}
          showCount={!mobile}
        />
        <StyledCompanies withSparkles text='Trusted by consumers & teams' />

        <SlackTitledBanner />

        <StyledImageAndCopySection
          copyProps={{
            title: 'Add unlimited contributors',
            subtitle:
              'We take the hassle out of long-distance celebration. With our dynamic group cards, invite as many individuals to the card, no matter where you are.',
            linkhref: '',
          }}
          render={() => (
            <MaxGroupGiftContainer style={{ marginLeft: 10, marginRight: 10, marginBottom: 24 }}>
              <img
                style={{
                  width: '100%',
                  minWidth: 300,
                  maxWidth: 600,
                }}
                src={slack_ss}
                alt='slack_screenshots'
              />
            </MaxGroupGiftContainer>
          )}
        />

        <StyledImageAndCopySection
          reverse={true}
          copyProps={{
            title: 'Express yourself effortlessly',
            subtitle:
              'Customize your group card with photos, GIFs, videos, and more. Our note suggestion library is here to help you craft your message – which you can make public or private within the card.',
            linkhref: '',
          }}
          render={() => (
            <MaxGroupGiftContainer>
              <ImageAndShadow
                src={initiatorEditCard}
                alt='illume card dashboard'
                shadowColor={theme.colors.orange}
              />
            </MaxGroupGiftContainer>
          )}
        />

        <StyledImageAndCopySection
          style={{
            marginBottom: 100,
          }}
          copyProps={{
            title: 'Schedule<br /> cards for teams',
            subtitle:
              'Stay on top of birthdays, holidays, and big moments for your team. With illume, you can schedule cards so you never miss an occasion.',
            linkhref: '',
          }}
          render={() => (
            <MaxGroupGiftContainer>
              <ImageAndShadow src={slack_bulk} alt='illume card dashboard' shadowColor='#FFBEA7 ' />
              <img
                src={man_with_glasses}
                style={{
                  position: 'absolute',
                  bottom: -40,
                  right: -20,
                  width: 156,
                }}
                alt='Man with Glasses'
              />
            </MaxGroupGiftContainer>
          )}
        />

        <LoveIllume testimonials={testimonialsV2} />

        <Heading>Pricing</Heading>

        <StyledPricingSection
          showTitle={false}
          id={'slack-page-pricing-section'}
          pricings={SLACK_PRICING_DATA}
          pricingAction={{
            onClick: (pricing) => {
              // console.log('pushing to main app', pricing);
              // const path = getMainAppFullPath('/slack-plans' + window.location.search);
              // window.location.href = path;
              window.location.href = SLACK_INSTALL_LINK;
            },
            name: 'Get Started',
          }}
          featureColumns={slackColumns}
        />

        <Join ctaSubtext='Bring people together no matter where they are.' showSlackBtn />

        <FooterNew />
        {/* <FutureOfCelebrating topics={marketingData.topics} />
        <CuratedGifts gifts={marketingData.gifts} />
        <GroupCard />
        <HowItWorks />
        <Footer links={footerLinksData} />
        <FooterNew /> */}
        {/* <Footer /> */}
      </Layout>
    </ThemeProvider>
  );
};

export default Homepage;
